<template>
  <div>
    <top></top>
    <navMenu></navMenu>
    <div class="inner clearfix">
            <navbar></navbar>
      <div class="fl zjleft">
        <div class="clearfix nav">
          <p @click="btn(1)" :class="centre == true ? 'active' : ''" class="fl">
            按知识点
          </p>
          <p
            @click="btn(2)"
            :class="centre == false ? 'active' : ''"
            class="fr"
          >
            按章节
          </p>
        </div>
        <knowledge
          v-if="centre == true"
          @zjpointid="zjpointid"
          @zjgrade="zjgrade"
          @zjsid="zjsid"
        ></knowledge>
        <chapterid
          v-else
          @chapterid="chapterid"
          @zjgid="zjgid"
          @zjsidCopy="zjsidCopy"
        ></chapterid>
      </div>
      <div class="fr zjright">
  
        <div v-if="centre == true">
          <filterBox
            :present="present"
            @difficulty="difficulty"
            @questionTypes="questionTypes"
            :gettypelist="gettypelist"
            :formatypearr="formatypearr"
          ></filterBox>
          <hint :listconhttpnum="listconhttpnum"></hint>

          <paper @del="del" v-if="display" :paperlist="listdispose"></paper>
          <div v-else class="zanwu" align="center"><img src="@/assets/image/zanwu.png" alt="" /></div>

          <!-- <pagination v-if="listconhttpnum >10" @pagenew="pagenew" :listconhttpnum="listconhttpnum"></pagination> -->
        </div>
        <div v-else>
          <filterBox
            :present="present"
            @difficulty="difficultyCopy"
            @questionTypes="questionTypesCopy"
            :gettypelist="gettypelistCopy"
            :formatypearr="formatypearr"
          ></filterBox>
          <hint :listconhttpnum="listconhttpnumCopy"></hint>

          <paper @del="delCopy" v-if="displayCopy" :paperlist="listdisposeCopy"></paper>
          <div v-else class="zanwu" align="center"><img src="@/assets/image/zanwu.png" alt="" /></div>

          <!-- <pagination v-if="listconhttpnum >10" @pagenew="pagenewCopy" :listconhttpnum="listconhttpnumCopy"></pagination> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueEvent from "@/api/eventBus.js";
import top from "@/components/navtop.vue";
import navMenu from "@/components/menuNav.vue";
import URL from "@/api/index";
import knowledge from "../../components/testPaper/knowledge";
import chapterid from "../../components/testPaper/chapterid";
import navbar from "../../components/testPaper/navbar";
import filterBox from "../../components/testPaper/filterBox";
import hint from "../../components/testPaper/hint";
import paper from "../../components/testPaper/paper.vue";
// import pagination from "../../components/resource/pagination";
export default {
  components: {
    top,
    navMenu,
    knowledge,
    chapterid,
    navbar,
    filterBox,
    hint,
    paper,
    // pagination,
  },
  data() {
    return {
      present: "",
      papernum: "",
      centre: true,
      // 知识点
      display:true,
      paperlist: [],
      listdispose:[],
      listconhttpnum: "",
      gettypelist: [],
      knowledgedata: {
        sid: 1,
        scid: "",
      },
      paperdata: {
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        question_type: "",
        question_level: "",
        point_id: "",
        chapter_id: "",
      },
      nums: 0,
      nums1: 0,
      // 章节
      displayCopy:true,
      paperlistCopy: [],
      listdisposeCopy:[],
      listconhttpnumCopy: "",
      gettypelistCopy: [],
      chapteriddata: {
        gid: "",
        sid: 1,
      },
      paperdataCopy: {
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        question_type: "",
        question_level: "",
        point_id: "",
        chapter_id: "",
      },
      numsCopy: 0,
      numsCopy1: 0,

      formatypearr: [
        { fmttype_name: "全部", fmttype_id: 0 },
        { fmttype_name: "容易", fmttype_id: 1 },
        { fmttype_name: "较易", fmttype_id: 2 },
        { fmttype_name: "一般", fmttype_id: 3 },
        { fmttype_name: "较难", fmttype_id: 4 },
        { fmttype_name: "困难", fmttype_id: 5 },
      ],
    };
  },
  created() {
    var centre = JSON.parse(localStorage.getItem("centre"))
    if (centre != null) {
      this.centre = centre
      // 数据上报
      var data = {path:'智能选题',id: this.centre == 'false'?'2':'1'}
      localStorage.setItem('optionwork',JSON.stringify(data))
    } else{
      // 数据上报
      var data = {path:'智能选题',id:'1'}
      localStorage.setItem('optionwork',JSON.stringify(data))
    }
  },
  watch: {
    nums(val) {
      if (this.paperlist.length  == val - this.nums1) {
        this.listdispose = this.paperlist
        localStorage.setItem("paperlist", JSON.stringify(this.paperlist))
      }
    },
    numsCopy(val) {
      if (this.paperlistCopy.length == val - this.numsCopy1) {
        this.listdisposeCopy = this.paperlistCopy
        localStorage.setItem("paperlist", JSON.stringify(this.paperlistCopy))
      }
    },
  },
  mounted(){
    //试题兼容
    setTimeout(() => {
        this.$nextTick(function () {
            if(this.commonsVariable.isMathjaxConfig){
                this.commonsVariable.initMathjaxConfig();
            }
            // this.commonsVariable.MathQueue("app");//传入组件id，让组件被MathJax渲染
        })
    },1000)
  },
  methods: {
    
    btn(e) {
      if (e == 1) {
        this.centre = true
      } else {
        this.centre = false
      }
      localStorage.setItem("centre", JSON.stringify(this.centre))

      // 数据上报
      var data = {path:'收藏选题',id: e}
      localStorage.setItem('optionwork',JSON.stringify(data))
    },
    //知识点题型筛选
    zjgrade(val) {
      this.present = val
      this.knowledgedata.scid = val
      this.getQuestionType(this.knowledgedata)
    },
    zjsid(val) {
      this.present = val
      this.knowledgedata.sid = val
      this.getQuestionType(this.knowledgedata)
    },
    //知识点试题筛选
    zjpointid(val) {
      // 选题id
      this.paperdata.point_id = val
      this.listconhttp(this.paperdata)
    },
    difficulty(val) {
      // 难度
      this.paperdata.point_id = JSON.parse(localStorage.getItem("chapterid"))
      this.paperdata.question_level = val
      this.listconhttp(this.paperdata)
    },
    questionTypes(val) {
      // 题型
      this.paperdata.point_id = JSON.parse(localStorage.getItem("chapterid"))
      this.paperdata.question_type = val
      this.listconhttp(this.paperdata)
    },
    pagenew(val) {
      // 页码
      this.paperdata.point_id = JSON.parse(localStorage.getItem("chapterid"))
      this.paperdata.page = val
      this.listconhttp(this.paperdata)
    },
    getQuestionType(e) {
      //获取知识点题库题型
      var data = {
        sid: e.sid,
        scid: e.scid,
      }
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelist = []
        this.gettypelist = this.gettypelist
        .concat(
          { type_id: 0, type_name: "全部" },
        )
        .concat(res.result)
      })
    },
    // 知识点取消收藏刷新
    del(val){
      this.listconhttp(this.paperdata)
    },
    // 收藏知识点试题列表
    listconhttp(e) {
      var data = {
        token: e.token,
        question_type: e.question_type,
        question_level: e.question_level,
        point_id: e.point_id,
        chapter_id: e.chapter_id,
      }
      this.$post(URL.GetQuestionList, data).then((res) => {
        if(res.result != ''){
          this.paperlist = []
          this.nums = 0
          this.nums1 = 0
          this.display = true
          this.GetQuestionById(res.result)
          this.listconhttpnum = res.result.length
        } else {
          this.listconhttpnum = 0
          this.display = false
          this.paperlist = []
          VueEvent.$emit("huifu", false)
        }
        // MathJax.Hub.Queue(['Typeset', MathJax.Hub])
        // window.MathJax.Hub.Queue(["Typeset", MathJax.Hub, document.getElementById('homeright')]);
      })
    },
    //根据题号获取题目
    GetQuestionById(e) {
      var that = this
      var data = {
        question_ids: JSON.stringify(e),
      }
      this.$post(URL.GetQuestionById, data).then((res) => {
        //知识点
        that.paperlist = res.result
        that.paperlist.forEach(function (data, index) {
          var html = data.question_text
          
          var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com"
          // store_type 1新 2旧题 判断
          if (data.store_type == 2) {
            var urltext = html.match(/(\S*)SYS/)[1]
            that.$get(data.answer).then((res1) => {
              var reg = new RegExp('src="', "g")
              var realurl2 = res1.replace(reg, 'src="' + urltext)
              that.paperlist[index].answer = realurl2
              that.nums++
            })
            that.$get(data.question_text).then((res2) => {
              var reg = new RegExp('src="', "g")
              var realurl2 = res2.replace(reg, 'src="' + urltext)
              that.paperlist[index].question_text = realurl2
              that.nums++
            })
            that.nums1++
          } else {
            // console.log('新题')

            //文本题目 //图片路径重新处理
            var reg = new RegExp('src="','g')
            // 题目
            var question_text = data.question_text.replace(reg, 'src="' + urltext2)
            // 选项
            var options_json = data.options_json.replace(reg, 'src="' + urltext2)
            // 答案
            var answer = data.answer.replace(reg, 'src="' + urltext2)
            // 解析
            var analysis = data.analysis.replace(reg, 'src="' + urltext2) 
            
            // console.log(that.paperlist[index].question_text)
            
            that.paperlist[index].question_text = question_text
            that.paperlist[index].options_json = options_json

            that.paperlist[index].analysis = analysis
            if(analysis){

            } else {
              that.paperlist[index].analysis = '略'
            }
            that.paperlist[index].answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + that.paperlist[index].analysis

            that.nums++
          }
          that.gettypelist.forEach(function (data1) {
            if (data.question_type == data1.type_id) {
              that.paperlist[index].question_type = data1.type_name
            }
          })
          that.formatypearr.forEach(function (data2) {
            if (data.question_level == data2.fmttype_id) {
              that.paperlist[index].question_level = data2.fmttype_name
            }
          })
        })
      })
    },

    //章节题型筛选
    zjgid(val) {
      this.present = val
      this.chapteriddata.gid = val
      this.getQuestionTypeCopy(this.chapteriddata)
    },
    zjsidCopy(val) {
      this.present = val
      this.chapteriddata.sid = val
      this.getQuestionTypeCopy(this.chapteriddata)
    },
    //章节试题筛选
    chapterid(val) {
      this.paperdataCopy.chapter_id = val
      this.paperlistconhttp(this.paperdataCopy)
    },
    difficultyCopy(val) {
      // 难度
      this.paperdataCopy.chapter_id = JSON.parse(localStorage.getItem("zjpointid"))
      this.paperdataCopy.question_level = val
      this.paperlistconhttp(this.paperdataCopy)
    },
    questionTypesCopy(val) {
      // 题型
      this.paperdataCopy.chapter_id = JSON.parse(localStorage.getItem("zjpointid"))
      this.paperdataCopy.question_type = val
      this.paperlistconhttp(this.paperdataCopy)
    },
    pagenewCopy(val) {
      // 页码
      this.paperdataCopy.chapter_id = JSON.parse(localStorage.getItem("zjpointid"))
      this.paperdataCopy.page = val
      this.paperlistconhttp(this.paperdataCopy)
    },
    getQuestionTypeCopy(e) {
      //获取章节题库题型
      var data = {
        sid: e.sid,
        gid: e.gid,
      }
      this.$post(URL.getQuestionType, data).then((res) => {
        this.gettypelistCopy = []
        this.gettypelistCopy = this.gettypelistCopy
          .concat(
            { type_id: 0, type_name: "全部" },
          )
          .concat(res.result)
      })
    },
    // 章节取消收藏刷新
    delCopy(val){
      this.paperlistconhttp(this.paperdataCopy)
    },
    // 章节收藏列表
    paperlistconhttp(e) {
      var data = {
        token: e.token,
        question_type: e.question_type,
        question_level: e.question_level,
        point_id: e.point_id,
        chapter_id: e.chapter_id,
      }
      this.$post(URL.GetQuestionList, data).then((res) => {
        if(res.result != ''){
          this.paperlistCopy = []
          this.displayCopy = true
          this.GetQuestionByIdCopy(res.result)
          this.listconhttpnumCopy = res.result.length
        } else {
          this.listconhttpnumCopy = 0
          this.displayCopy = false
          this.paperlistCopy = []
          VueEvent.$emit("huifu", false)
        }
      })
    },
    
    // 章节试题列表
    GetQuestionByIdCopy(e) {
      var that = this
      var data = {
        question_ids: JSON.stringify(e),
      }
      this.$post(URL.GetQuestionById, data).then((res) => {
        //章节
        if (res.result) {
          that.paperlistCopy = res.result
          that.paperlistCopy.forEach(function (data, index) {
            var html = data.question_text
            
            var urltext2 = "http://rjwtiku.oss-cn-beijing.aliyuncs.com"
            // store_type 1新 2旧题 判断
            that.numsCopy = 0
            if (data.store_type == 2) {
              var urltext = html.match(/(\S*)SYS/)[1]
              that.$get(data.answer).then((res1) => {
                var reg = new RegExp('src="', "g")
                var realurl2 = res1.replace(reg, 'src="' + urltext)
                that.paperlistCopy[index].answer = realurl2
                that.numsCopy++
              })
              that.$get(data.question_text).then((res2) => {
                var reg = new RegExp('src="', "g")
                var realurl2 = res2.replace(reg, 'src="' + urltext)
                that.paperlistCopy[index].question_text = realurl2
                that.numsCopy++
              })
              that.numsCopy1++
            } else {
              // console.log('新题')

              //文本题目 //图片路径重新处理
              var reg = new RegExp('src="','g')
              // 题目
              var question_text = data.question_text.replace(reg, 'src="' + urltext2)
              // 选项
              var options_json = data.options_json.replace(reg, 'src="' + urltext2)
              // 答案
              var answer = data.answer.replace(reg, 'src="' + urltext2)
              // 解析
              var analysis = data.analysis.replace(reg, 'src="' + urltext2) 
              
              // console.log(that.paperlist[index].question_text)
              
              that.paperlistCopy[index].question_text = question_text
              that.paperlistCopy[index].options_json = options_json

              that.paperlistCopy[index].analysis = analysis
              if(analysis){

              } else {
                that.paperlistCopy[index].analysis = '略'
              }
              that.paperlistCopy[index].answer = "<div>答案:</div>"+ answer + "<div>解析:</div>" + that.paperlistCopy[index].analysis

              that.numsCopy++
            }
            that.gettypelistCopy.forEach(function (data1) {
              if (data.question_type == data1.type_id) {
                that.paperlistCopy[index].question_type = data1.type_name
              }
            })
            that.formatypearr.forEach(function (data2) {
              if (data.question_level == data2.fmttype_id) {
                that.paperlistCopy[index].question_level = data2.fmttype_name
              }
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.zjleft {
  width: 19%;
}
.zjright {
  width: 77%;
}
.nav p {
  margin-top: 24px;
  width: 110px;
  line-height: 32px;
  color: #2f2f2f;
  text-align: center;
  font-size: 16px;
  background-color: #e8e7e7;
  border-radius: 5px;
  cursor: pointer;
}
.active {
  background-color: #378aff !important;
  color: #fff !important;
}
.zanwu{
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px 
		rgba(200, 200, 200, 0.47);
  img{
    padding: 20px 0;
    
  }
}
</style>
